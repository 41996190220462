import tw from "tailwind.macro"

export const StyledFooter = tw.footer`
  bg-white opacity-75 border-1 border-t border-solid border-gray-300 bottom-0 text-base font-light
`

export const StyledSection = tw.div`flex-1 text-left`

export const StyledSectionTitle = tw.h3`uppercase text-gray-600 md:mb-6 text-left mt-4 font-medium`

export const StyledSectionList = tw.ul`list-reset mb-6 text-left`
