import CommonEvents from "./events"
import CommonPages from "./pages"
import CommonProperties from "./properties"
import { DEFAULT_PAGE_SIZE } from "../utils"
import { mixpanel } from "gatsby-plugin-mixpanel"

export {
  CommonProperties as PROPERTIES,
  CommonEvents as EVENTS,
  CommonPages as PAGES,
}

export default {
  trackEvent: (event, value, properties = {}) => {
    mixpanel.track(event, {
      ...value,
      ...properties,
    })
  },
  paginationProperties: offset => ({
    [CommonProperties.CURRENT_PAGE_NUMBER]:
      (offset || DEFAULT_PAGE_SIZE) / DEFAULT_PAGE_SIZE,
    [CommonProperties.CURRENT_PAGE_SIZE]: DEFAULT_PAGE_SIZE,
  }),
  pageProperties: name => ({
    [CommonProperties.PAGE_NAME]: name,
  }),
  netlifyProperties: (branch, context) => ({
    [CommonProperties.BRANCH]: branch,
    [CommonProperties.CONTEXT]: context,
  }),
}
