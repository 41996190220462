import React from "react"
import { StyledEntitiesContainer } from "./styled/entities"

export default ({ children }) => {
  return (
    <StyledEntitiesContainer className="container">
      {children}
    </StyledEntitiesContainer>
  )
}
