import { StyledContainer, StyledHeroContent } from "./styled/hero"

import React from "react"

function HeroContainer({ title, children }) {
  return (
    <StyledContainer>
      <StyledHeroContent className="container">{children}</StyledHeroContent>
    </StyledContainer>
  )
}

HeroContainer.defaultProps = {
  title: "",
}

export default HeroContainer
