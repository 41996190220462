import get from "lodash/get"
import isNil from "lodash/isNil"

/**
 *
 * @param {string|Array<string>} fields
 * @param {Object} item
 *
 * Returns a single string from the item properties (fields) or empty string
 */
export const searchableTextFromProperties = (fields, item) => {
  if (isNil(item)) return ""

  if (typeof fields == "string" || fields instanceof String) {
    return get(item, fields, "")
  } else if (Array.isArray(fields)) {
    return fields
      .filter(field => get(item, field, false))
      .map(field => get(item, field))
      .join(" ")
  } else {
    return ""
  }
}
