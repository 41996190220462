export default {
  header: {
    bg: "#fff",
  },
  content: {
    bg: "#f8f8f8",
  },
  hero: {
    color: "#fff",
    bg: "linear-gradient(45deg, #8676bb, #9e6ba7)",
  },
  search: {
    result: {
      color: "#fff",
    },
  },
}
