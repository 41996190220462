import React from "react"
import CookieConsent from "react-cookie-consent"
import CommonAnalytics, { EVENTS } from "../../common/analytics"
import "../../css/style.css"

function CookieConsentBanner() {
  const trackAccept = () => {
    CommonAnalytics.trackEvent(EVENTS.COOKIE_CONSENT_ACCEPTED)
  }

  return (
    <CookieConsent
      location="bottom"
      buttonText="I understand"
      cookieName="whatmineapp"
      style={{ background: "#2B373B" }}
      contentClasses="flex flex-col w-full md:w-3/4 m-0 p-3"
      contentStyle={{ flex: null, margin: null }}
      containerClasses="flex flex-col z-50 m-0 p-1"
      buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
      onAccept={trackAccept}
    >
      <h3 className="my-0 text-lg font-semibold">This website uses cookies</h3>
      <p className="text-sm">
        We use cookies to personalise content, enhance the user experience and
        to analyse our traffic.
      </p>
      <p className="text-sm">
        You consent to our cookies if you continue to use our website.
      </p>
    </CookieConsent>
  )
}

export default CookieConsentBanner
