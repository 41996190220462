import styled from "@emotion/styled"
import tw from "tailwind.macro"

export const StyledMain = styled.div`
  ${tw`min-h-screen w-full flex flex-col`}
  background-color: ${props => props.theme.content.bg};
  margin: 0;
  padding: 0;
  width: 100%;
`

export const StyledMainContent = tw.div`
  mx-0 p-0 flex-grow w-full h-full mt-16
`
