import React, { useContext } from "react"
import {
  StyledFooter,
  StyledSection,
  StyledSectionList,
  StyledSectionTitle,
} from "./styled/footer"
import { graphql, useStaticQuery } from "gatsby"

import { BuildContext } from "../../common/utils/index"
import CustomLink from "../../common/components/CustomLink"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { RouteInfo } from "../../common/utils"
import { fab } from "@fortawesome/free-brands-svg-icons"
import { library } from "@fortawesome/fontawesome-svg-core"
import logo from "../../images/logo_text_right_black.svg"

library.add(fab)

function SocialLink({ url, children }) {
  return (
    <li className="inline-block p-2">
      <a href={url} className="no-underline hover:underline">
        {children}
      </a>
    </li>
  )
}

const useLegalData = () => {
  const { allContentfulLegal } = useStaticQuery(
    graphql`
      query {
        allContentfulLegal(filter: { isCompetition: { eq: false } }) {
          edges {
            node {
              title
              slug
            }
          }
        }
      }
    `
  )
  return allContentfulLegal
}

const LegalLinks = () => {
  const { edges } = useLegalData()
  return (
    <StyledSectionList>
      {edges.map(({ node }) => (
        <CustomLink url={`/${node.slug}`} key={node.slug}>
          {node.title}
        </CustomLink>
      ))}
      <CustomLink url={RouteInfo.COMPETITIONS}>Competitions</CustomLink>
    </StyledSectionList>
  )
}

const LogoSection = ({ title, children }) => {
  return (
    <div className="flex flex-1 justify-start items-start">
      <a href={RouteInfo.HOME} className="no-underline hover:underline link">
        <img src={logo} alt="WhatsMine Logo" className="w-1/3 md:w-4/5 py-2" />
      </a>
    </div>
  )
}

const SocialSection = () => {
  return (
    <StyledSection>
      <SocialLink url="https://www.facebook.com/WhatsMineApp/">
        <FontAwesomeIcon size="2x" icon={["fab", "facebook"]} />
      </SocialLink>
      <SocialLink url="https://www.instagram.com/whatsmine_app/">
        <FontAwesomeIcon size="2x" icon={["fab", "instagram"]} />
      </SocialLink>
      <SocialLink url="https://www.linkedin.com/company/whatsmineapp/">
        <FontAwesomeIcon size="2x" icon={["fab", "linkedin-in"]} />
      </SocialLink>
    </StyledSection>
  )
}

const Section = ({ title, children }) => {
  return (
    <StyledSection>
      <StyledSectionTitle>{title}</StyledSectionTitle>
      <StyledSectionList>{children}</StyledSectionList>
    </StyledSection>
  )
}

function Footer() {
  const { isProduction } = useContext(BuildContext)
  return (
    <StyledFooter>
      <div className="container mx-auto px-2">
        <div className="flex flex-col md:flex-row w-full text-center py-12">
          <LogoSection />
          <Section title="Company">
            <CustomLink url={RouteInfo.ABOUT}>About</CustomLink>
            <CustomLink url={RouteInfo.ARTICLES}>Blog</CustomLink>
            <CustomLink
              isExternal={true}
              url="https://intercom.help/whatsmineapp"
            >
              Help
            </CustomLink>
          </Section>

          <Section title="Legal">
            <LegalLinks />
          </Section>
          <SocialSection />
        </div>
        <div className="w-full flex flex-col md:flex-row text-center py-6">
          <small className="w-full text-center text-gray-600">
            ©2019 WhatsMine, All Rights Reserved
          </small>
        </div>
      </div>
    </StyledFooter>
  )
}

export default Footer
