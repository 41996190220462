export default {
  BRANCH_LINK: "Branch Link",
  CURRENT_PAGE_NUMBER: "Current Page Number",
  CURRENT_PAGE_SIZE: "Current Page Size",
  STORE_NAME: "Store Name",
  PAGE_NAME: "Page Name",
  PAGE_VERSION: "Page Version",
  SHOWCASE_ITEM: "Showcase Item",
  BRANCH: "Branch",
  CONTEXT: "Context",
}
