import styled from "@emotion/styled"
import tw from "tailwind.macro"

export const StyledContainer = styled.div`
  background: ${props => props.theme.hero.bg};
`

export const StyledHeroContent = tw.div`py-6 h-24 mx-auto flex`

export const StyledHeroTitle = tw.h2`
  text-white text-xl md:text-2xl mt-0 mx-2 md:mx-0 font-medium
`
