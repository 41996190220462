import React, { useContext, useState } from "react"

import { AppContext } from "../../common/utils/appUtils"
import { BuildContext } from "../../common/utils"
import { Link } from "gatsby"
import appRoutes from "../../common/utils/appRoutes"
import { isBrowser } from "../../common/utils/appUtils"
import logo from "../../images/logo_colour_background.svg"

const PageMap = {
  [appRoutes.ARTICLES]: "Articles",
  [appRoutes.OFFERS]: "Offers",
  [appRoutes.BRANDS]: "Brands",
  [appRoutes.CAFES]: "Cafes",
  [appRoutes.SIGN_IN]: "Sign in",
  [appRoutes.SIGN_IN_CODE]: "Sign in code",
  [appRoutes.ACCOUNT]: "Account",
  [appRoutes.SIGN_OUT]: "Sign out",
  [appRoutes.CALLBACK]: "Success",
  [appRoutes.WALLET]: "Wallet",
}

const pathTitle = () => {
  let title = "WhatsMine"

  Object.keys(PageMap).forEach(k => {
    if (isBrowser && window.location.pathname.match(new RegExp(k))) {
      title = PageMap[k]
    }
  })

  return title
}

function Header() {
  const { isProduction } = useContext(BuildContext)
  const { isSignedIn } = useContext(AppContext)
  const [menuHidden, setMenuHidden] = useState(true)

  return (
    <nav className="flex w-full items-center justify-between flex-wrap h-16 shadow shadow-md bg-white fixed z-20">
      <div className="flex items-center flex-shrink-0 text-black mr-6 h-16 px-2">
        <div className="flex focus:none items-center">
          <a href={appRoutes.HOME}>
            <img src={logo} alt="WhatsMine Logo" className="h-8 rounded mr-4" />
          </a>
        </div>
        <span className="font-semibold text-xl tracking-tight">
          {pathTitle()}
        </span>
        {isProduction === false ? (
          <span className="font-medium text-base text-red-300 ml-4 italic">
            Dev mode
          </span>
        ) : null}
      </div>
      <div className="block lg:hidden px-2">
        <button
          onClick={() => setMenuHidden(!menuHidden)}
          className="flex w-10 h-10 items-center justify-center rounded border border-gray-400 focus:outline-none pointer-cursor"
        >
          <svg
            className="fill-current h-6 w-6 border-gray-400"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <title>Menu</title>
            <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
          </svg>
        </button>
      </div>
      <div
        className={`w-full flex-grow lg:flex lg:items-center lg:w-auto bg-white px-2 pb-4 lg:pb-0 shadow lg:shadow-none ${
          menuHidden ? "hidden" : "block"
        }`}
      >
        <div className="lg:flex-grow lg:flex md:justify-end text-base">
          <Link
            partiallyActive={true}
            to={appRoutes.BRANDS}
            activeClassName="active-link"
            className="link block mt-4 lg:inline-block lg:mt-0 mr-4 font-semibold"
          >
            Brands
          </Link>
          <Link
            partiallyActive={true}
            to={appRoutes.ARTICLES}
            activeClassName="active-link"
            className="link block mt-4 lg:inline-block lg:mt-0 mr-4 font-semibold"
          >
            Articles
          </Link>
          {isSignedIn && !isProduction && (
            <Link
              partiallyActive={true}
              to={appRoutes.ACCOUNT}
              activeClassName="active-link"
              className="link block mt-4 lg:inline-block lg:mt-0 mr-4 font-semibold"
            >
              Account
            </Link>
          )}
          {/* {isSignedIn && !isProduction && (
            <Link
              partiallyActive={true}
              to={appRoutes.WALLET}
              activeClassName="active-link"
              className="link block mt-4 lg:inline-block lg:mt-0 mr-4 font-semibold"
            >
              Wallet
            </Link>
          )} */}
          {!isSignedIn && !isProduction && (
            <Link
              to={appRoutes.SIGN_IN}
              activeClassName="active-link"
              className="link block mt-4 lg:inline-block lg:mt-0 mr-4 font-semibold"
            >
              Sign in
            </Link>
          )}
          {isSignedIn && !isProduction && (
            <Link
              to={appRoutes.SIGN_OUT}
              state={{ redirectTo: window.location.pathname }}
              className="link block mt-4 lg:inline-block lg:mt-0 mr-4 font-semibold"
            >
              Sign out
            </Link>
          )}
          <a
            href="https://intercom.help/whatsmineapp"
            className="link block mt-4 lg:inline-block lg:mt-0 mr-4 font-semibold"
          >
            FAQ
          </a>
        </div>
      </div>
    </nav>
  )
}

export default Header
