import { Link } from "gatsby"
import React from "react"

export function CustomLink({ url, isExternal, children }) {
  return (
    <li className="mt-2 mr-2 block md:mr-0">
      {isExternal ? (
        <a href={url} className="link no-underline hover:underline">
          {children}
        </a>
      ) : (
        <Link to={url} className="link no-underline hover:underline">
          {children}
        </Link>
      )}
    </li>
  )
}

CustomLink.defaultProps = {
  isExternal: false,
}

export default CustomLink
