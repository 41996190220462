import { createSelector } from "reselect"
import get from "lodash/get"

const searchState = state => state.search

export const searchEntityType$ = createSelector(
  [searchState],
  state => state.entityType
)

export const filter$ = createSelector(
  [searchState, searchEntityType$],
  (state, entityType) => get(state[entityType], "filter")
)

export const search$ = createSelector(
  [searchState, searchEntityType$],
  (state, entityType) => get(state[entityType], "search")
)
