import * as selectors from "../../auth/store/selectors/token-selector"

import React, { useEffect } from "react"
import { StyledMain, StyledMainContent } from "./styled/layout"

import { AppContext } from "../../common/utils/appUtils"
import CookieConsentBanner from "./CookieConsentBanner"
import Footer from "./Footer"
import Header from "./Header"
import PropTypes from "prop-types"
import ReactTooltip from "react-tooltip"
import { ThemeProvider } from "emotion-theming"
import { checkSessionStarted } from "../../auth/store/actions/actionCreators"
import { connect } from "react-redux"
import defaultTheme from "../../common/themes/defaultTheme"

const Layout = ({
  checkAuth,
  checkSessionStarted,
  children,
  isSignedIn,
  theme,
}) => {
  const appContextValue = { isSignedIn }

  useEffect(() => {
    if (isSignedIn && checkAuth) {
      checkSessionStarted()
    }
  }, [isSignedIn, checkAuth])

  return (
    <AppContext.Provider value={appContextValue}>
      <ThemeProvider theme={theme}>
        <StyledMain>
          <Header />
          <ReactTooltip />
          {process.env.GATSBY_ENABLE_COOKIE_CONSENT && <CookieConsentBanner />}
          <StyledMainContent>{children}</StyledMainContent>
          <Footer />
        </StyledMain>
      </ThemeProvider>
    </AppContext.Provider>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  theme: PropTypes.object,
  loadUserOnCheckAuth: PropTypes.bool,
}

Layout.defaultProps = {
  theme: defaultTheme,
  checkAuth: true,
}

const mapStateToProps = state => ({
  isSignedIn: selectors.isSignedIn$(state),
})

const mapDispatchToProps = {
  checkSessionStarted,
}

export default connect(mapStateToProps, mapDispatchToProps)(Layout)
