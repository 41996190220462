import { DEFAULT_PAGE_SIZE, deserializeFilterFromQueryString } from "../utils"
import { useEffect, useState } from "react"

import CommonAnalytics from "../analytics"
import { RouteInfo } from "../utils"
import { navigate } from "gatsby"

/**
 * useTrackEventEffect run effect to track an event
 * @param {string} event - analytics event
 * @param {Object} properties - analytics properties
 * @param {Array} deps - useEffect dependencies
 */
export function useTrackEventEffect(event, properties, deps = []) {
  useEffect(() => {
    CommonAnalytics.trackEvent(event, properties)
  }, deps)
}

export function useDisabledIntercomEffect() {
  useEffect(() => {
    Intercom("update", {
      hide_default_launcher: true,
    })

    return () => {
      Intercom("update", {
        hide_default_launcher: false,
      })
    }
  }, [])
}

export function useSourceDataEffect(loadCallback, entities, entityIds) {
  useEffect(() => {
    loadCallback({
      entities,
      entityIds,
    })
  }, [])
}

export function useSearchResultEffect(setResults, results) {
  useEffect(() => {
    setResults(results)
  }, [results])
}

export function useDebounce(value, delay) {
  const [debouncedValue, setDebouncedValue] = useState(value)

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value)
    }, delay)
    return () => {
      clearTimeout(handler)
    }
  }, [value])

  return debouncedValue
}

export function useDevelopmentEffect(isProduction) {
  useEffect(() => {
    if (isProduction) {
      navigate(RouteInfo.HOME)
    }
  }, [])
}

export const useFilterLoader = (
  location,
  filterTypes,
  filter,
  addFilter,
  loadData,
  offset
) => {
  useEffect(() => {
    const filterKey = deserializeFilterFromQueryString(location, filterTypes)

    const queryFilter = filterTypes[filterKey]
    const shouldUpdateFilter = queryFilter && queryFilter !== filter

    if (shouldUpdateFilter) {
      addFilter(queryFilter)
    }

    // We never need to load ALL data as it is statically available
    const shouldLoadData = queryFilter !== filterTypes.ALL
    if (shouldLoadData) {
      loadData(queryFilter, offset, DEFAULT_PAGE_SIZE)
    }

    return () => {}
  }, [location])
}
